import React, { useEffect, useState } from "react";
import "../form/NewUserform.css";
import { config } from "../../config";
import axios from "axios";
function CreateUserForm(prop) {
  let name = prop.editdata;

  const [row_id, setrow_id] = useState(prop.editdata.row_id || "");
  const [fullName, setFullName] = useState(prop.editdata.fullname || "");
  const [MobileNumber, setMobileNumber] = useState(prop.editdata.mobile || "");
  const [Address, setAddress] = useState(prop.editdata.address || "");
  const [City, setCity] = useState(prop.editdata.city || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show_msg,setshow_msg] = useState(false)

  ///   success msg display show   //////////////
  const [successmsg, setsuccessmsg] = useState(false);
  const [isvalid, setisvalid] = useState(true);

  /// states handler for input ///
  const handleFullNameChange = (e) => {
    
    if (e.target.value.trim().length > 0) {
      setisvalid(true);
    }

    setFullName(e.target.value);
  };

  const handleMobile_NumberChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setisvalid(true);
    }

    setMobileNumber(event.target.value);
  };

  const handleAddressChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setisvalid(true);
    }
    setAddress(event.target.value);
  };

  const handleCityChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setisvalid(true);
    }

    setCity(event.target.value);
  };

  //////////------------//////

  //------- for add new user --------//////
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("submit");
    if (
      fullName.length === 0 ||
      MobileNumber.length === 0 ||
      Address.length === 0 ||
      City.length === 0
    ) {
      setisvalid(false);
      return;
    }

    let data = {
      fullname: fullName,
      mobile: MobileNumber,
      address: Address,
      city: City,
    };

  
    let payload = { fn: "common_fn", se: "cr_us", data: data };

    let reqbody = JSON.stringify(payload);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
      // const data = await response.json();
      console.log(response);
      if (response.status === 200) {
        if (response.data.status === 0) {
          setErrorMessage(response.data.msg);
          setsuccessmsg(true);
 
        }
        
        else if (response.data.status === 2){
setshow_msg(response.data.msg)

        }
        
        else {
          setErrorMessage(response.data.msg || "Ops something went wrong");
        }
        setLoading(false);
      } else {
        // Handle login error
        const { msg } = await response.data;
        setLoading(false);

        setErrorMessage(msg);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);

      setErrorMessage("opp something went go");
      setsuccessmsg(true);
    }

  };

  //////////-------------- for update-----------------////////
  const handleupdate = async () => {
    console.log("edit");
    let data = {
      row_id: row_id,
      fullname: fullName,
      mobile: MobileNumber,
      address: Address,
      city: City,
    };

    let payload = { fn: "common_fn", se: "ed_us", data: data };

    let reqbody = JSON.stringify(payload);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
      // const data = await response.json();
      console.log(response);
      if (response.status === 200) {
        if (response.data.status === 0) {
          setErrorMessage(response.data.msg);
          setsuccessmsg(true);
        } else {
          setErrorMessage(response.data.msg || "Ops something went wrong");
        }
        setLoading(false);
      } else {
        // Handle login error
        const { msg } = await response.data;
        setLoading(false);

        setErrorMessage(msg);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);

      setErrorMessage("opp something went go");
      setsuccessmsg(true);
    }

    setsuccessmsg(true);
  };

  return (
    <div>
      {!successmsg ? (
        <div className="form-container">
          <h6>{name ? "Update" : "Create New User"}</h6>
          <div className="input-row">
            <div>
              <label htmlFor="full-name" className="input-label">
                Full Name
              </label>
              <div className={`input-container ${!isvalid ? "invalid-2" : ""}`}>
                <input
                  type="text"
                  id="full-name"
                  value={fullName}
                  onChange={handleFullNameChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor="whatsapp-number" className="input-label">
                Mobile.no
              </label>
              <div className={`input-container ${!isvalid ? "invalid-2" : ""}`}>
                <input
                  type="text"
                  id="whatsapp-number"
                  value={MobileNumber}
                  onChange={handleMobile_NumberChange}
                />
              </div>
            </div>
          </div>
          <div className="input-row">
            <div>
              <label htmlFor="address" className="input-label">
                Address
              </label>
              <div className={`input-container ${!isvalid ? "invalid-2" : ""}`}>
                <input
                  type="text"
                  id="address"
                  value={Address}
                  onChange={handleAddressChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor="city" className="input-label">
                City
              </label>
              <div className={`input-container ${!isvalid ? "invalid-2" : ""}`}>
                <input
                  type="text"
                  id="city"
                  value={City}
                  onChange={handleCityChange}
                />
              </div>
            </div>
          </div>
          <div className="input-row">
            <button
              className="submit-btn"
              onClick={name ? handleupdate : handleSubmit}
            >
              {name ? "Update" : "Submit"}
            </button>

          </div>
         {show_msg &&
        <p style={{color:"red"}}>{show_msg}</p>
         }
        </div>
      ) : (

        <p className="success-msg">{errorMessage}</p>
      )}
    </div>
  );
}

export default CreateUserForm;
