import React, { useState } from 'react';
import "./image_slider.css"
import { config } from "../../config";

const ImageSlider = ({ images }) => {


  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };




  return (
    <div className="image-slider">
      <button onClick={prevImage} className="slider-button prev-button">
        &lt;
      </button>
      <img src={`${config.apiBaseURL}/${images[currentImageIndex].url}`} alt="Image" className="slider-image" />
      <button onClick={nextImage} className="slider-button next-button">
        &gt;
      </button>
    </div>
  );
};

export default ImageSlider;
