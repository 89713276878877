import React,{useState,useEffect,useRef} from "react"
import "./../book_template/book_template.css"
import { config } from "../../config";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
// import html2pdf from 'html2pdf.js';
import logo from "./book_logo.png"
import ImageSlider from "../All_book/image_slider";
import Img_modal from "./../All_book/Img_modal";
import def from "./def2.jpg"
import flower from "./flower.png"







function Book_template() {
    
    const [data,SetData] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [show_slider,setshow_slider] = useState(false)
    const [Obj,setObj] = useState(true)
    

    async function fetchbook() {
        let payload = {
          fn: "common_fn",
          se: "fe_bo_tem",
          data: { limit: null, page: currentPage },
        };
    
        let reqbody = JSON.stringify(payload);
        // Fetch racks data from
        try {
          setLoading(true)
          const response = await axios.post(
            `${config.apiBaseURL}/common`,
            { payload: reqbody },
            { headers: { "Content-Type": "application/json" } }
          );
    
          const data = response.data.data;
          console.log(response,'............');
          if (response.status === 200 && response.data.status === 0) {
            SetData(data);
            setLoading(false)
          }
        } catch (error) {
          console.log(error);
        }
        setLoading(false)
      }


      useEffect(()=>{
        fetchbook()

      },[currentPage])



function open_slider_handler (obj){

  
  if(obj.img_arr === null ||obj.img_arr.length <= 0 ){
    return
  }



  setObj(obj)
  setshow_slider(true)
}


   
  const convertToPdf = () => {
    const pdf = new jsPDF('landscape', 'px', 'b4'); // Specify 'a4' for A4 size landscape mode
    
    // Get the HTML element to be converted
    const divElement = document.getElementById('divToPrint');
    
    // Convert the div to a canvas
    html2canvas(divElement, {
        allowTaint: true,
        useCORS: true,
        scale:3
    }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        
        console.log(canvas.width,"......................")
        // Calculate the aspect ratio of the canvas
        const aspectRatio = canvas.height / canvas.width;
        
        // Set the width and height for the PDF
        const pdfWidth = 740.28; // A4 width in pt for landscape mode
        const pdfHeight = pdfWidth * aspectRatio;
        
        // Set padding from left and top
        const paddingLeft = 10;
        const paddingTop = 50;
        
        // Add the image to the PDF with padding
        pdf.addImage(imgData, 'PNG', 15 + paddingLeft, 15 + paddingTop, pdfWidth - 30, pdfHeight - 30); // Leave some margin (30pt) for better appearance
  
        // pdf.addImage(imgData, 'PNG', 15+ paddingLeft, 15 + paddingTop, (pdfWidth - 30) + 20, pdfHeight - 30);

        // Save the PDF
        pdf.save('books.pdf');
    });
};


return (
<>
{
    loading ? <BeatLoader/>:
<div className='showbookStorebydk'>
  {
    show_slider && <Img_modal images ={Obj} setshow_slider ={setshow_slider}/>
  } 

            <div className='mainshowbookStorebydk' id ="divToPrint">
                {/* <div className='headingformainshowbookStorebydk'>
                    <div className='logoheading'><img src={logo}></img></div>
                    <div className='headingonly'><p>अखिल भारतीय श्री जैन रत्न ग्रन्थालय एवं शोध केन्द्र, जोधपुर</p> </div>
                    <div className='logoheading'><img src={logo}></img></div>
                </div> */}
                <div className='booksmainshowbookStorebydk'>


                         {  data.length > 0 ?



                     data.map((e,i) =>{


                      // {`${config.apiBaseURL}/${e.img}`}

                return <div className='bookcart'  key={i}>
                  <div className="bookcart_row_1">
                        <div className='bookcartimg'>
                            <img src={e.img_arr !== null && e.img_arr.length ? `${config.apiBaseURL}/${e.img_arr[0].url}`:def} onClick={()=>{

open_slider_handler(e)
                            }}></img>
                        </div>
                        <div className='bookcartcontent'>
                            <div className='bookdetailattribute bold' id="booknametext">{e.book_hindi_name !==null ? e.book_hindi_name:e.bookname },</div>
                            <div className='bookdetailattribute bold'> {e.authorname},</div>
                            <div className='bookdetailattribute bold' id ="publisher12">{e.publisher !== null? e.publisher :""}</div>
                            {/* <div className='bookdetailattribute bold'>{e.deptname != null? e.deptname :""},{e.subdeptname != null? e.subdeptname :""}</div> */}
                            <div className='bookdetailattribute bold'>{e.language !== null ? e.language : ""}</div>
                            {/* <div className='bookdetailattribute bold'>पृष्ठ -{e.book_pages != null? e.book_pages :""}</div> */}

                        </div>
                        <div >
                          <div id="bk">
                          <img id ="sk"src={flower}/>
                          <div id ="nk">{e.book_no}</div>
                          <img id ="sk"src={flower}/>
                          </div>
                          <div className='bookdetailattribute bold'>पृष्ठ -{e.book_pages !== null? e.book_pages :""}</div>
                        </div>
     
                    </div>
                    <div  className="bookcart_row_2" ><p className='bookdetailattribute2 bold'>{e.summary !== null ? e.summary:""}</p></div>
                
                    </div>
                     })


                    :<p style={{textAlign:"center",width:"100%"}}> no data </p>

                         }
                   
                  
                   
                </div>
              
            </div>
            <div className='btnmainshowbookStorebydk'>
                    <div>
                    <button
          className={`${
            currentPage === 1 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
                        <span>page {currentPage}</span>
                        <button
          className={`${
            data.length < 12 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage < 1 || data.length < 12}
        >
          Next
        </button>
                    </div>
                    <div>
                        <button style={{backgroundColor:"green",color:"white"}} className="pagination-btn" onClick={convertToPdf}>Save as PDF</button>
                    </div>
                </div>
        </div>

        }
        </>

)





}


export default Book_template;