import React from "react";
import "./card.css";

const Card = (prop) => {
  // console.log(prop.Edit_fun);
  let data = prop.data[0];
  


const EditHandler = ()=>{

prop.Edit_fun(data)

}




  return (
    <div className="card">
      <div className="card-content">
        <div className="card-row">
          <div className="card-label">Name:</div>
          {/* <div className="card-value">{name}</div> */}
          <div className="card-value">{data.fullname}</div>
        </div>
        <div className="card-row">
          <div className="card-label">Address:</div>
          <div className="card-value">
            
            {data.address}
          </div>
        </div>
        <div className="card-row">
          <div className="card-label">Mobile:</div>
          <div className="card-value">{data.mobile}  </div>
     
          <button className="edit-button" onClick={EditHandler}>Edit</button>
         
        </div>
      </div>
    </div>
  );
};

export default Card;
