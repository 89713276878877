import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "../../config";
import  {time_difference} from "../../services/Helperfunction"
import { BeatLoader } from "react-spinners";
import "../upcoming_book_return/upcoming_book_return.css"


function reverseDateString(dateString) {
  // Split the string into an array using '-' as the separator
  const dateArray = dateString.split('-');

  // Reverse the order of the elements in the array
  const reversedDateArray = dateArray.reverse();

  // Join the elements of the reversed array into a string using '-' as the separator
  const reversedDateString = reversedDateArray.join('-');

  return reversedDateString;
}


const Upcoming_books_return = React.memo((props) => {

  const [upcoming,setupcoming] = useState([])
  
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("no record");
  const [loading, setLoading] = useState(false);
  
  
  
  // console.log('upcomimg')

///////////////   ----data fetch-----  ///////////////

useEffect(() => {
  async function fetchdata() {
    setLoading(true);
    let payload = {
      fn: "common_fn",
      se: "fe_is",
      data: { limit: 10, page: currentPage },
    };


    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

   
      const data = response.data.data;
      // console.log(data)
      if (response.status === 200) {
        if (response.data.status === 0 && data.length > 0) {

          let issue =  data.map((book)=>{

          book.return_t =  book.returnafter -  time_difference(book.cr_on)
          return book
          })     

          setupcoming(issue);
        } else if (response.data.status === 1) {
          setupcoming([]);

          
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Oops, something went wrong')
    }

    setLoading(false);
  }
  fetchdata();
}, [currentPage]);








  return (
    <div>
    { loading ? <BeatLoader color="#123abc" className="spiner-postion" /> :
      <>
            { upcoming && upcoming.length > 0 ?

      <table className="rack-table">
              <thead>
                <tr>
                  <th>Book</th>
                  <th>User</th>
                  <th>issue on </th>
                  <th>Mobile</th>
                  <th>Remaining time</th>
                </tr>
              </thead>

     
              <tbody>
                {upcoming.map((book,i) => (
                  <tr key={`${book.cr_on}+${i}`} className="rack-row">
                  
                    <td className="rack-name">{book.bookname}<br></br> {book.book_hindi_name}</td>
                    <td className="rack-name">{book.fullname}</td>
                    <td className="rack-description">{reverseDateString(book.cr_on.split("T")[0])}</td>
                    <td className="rack-descriptio">{book.mobile}</td>
                    <td className={`${book.return_t <= 2 ?book.return_t <= 0 ?'cross-timeline': 'near-timeline':''}`}>{book.return_t} days</td>
                    
                  </tr>
                ))}
              </tbody>
       </table>
 :<p> {errorMessage} </p>}




{/*         pagination */}



       <div className="sub-page-div">
        <button
          className={`${
            currentPage === 1 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="Page-text">Page {currentPage}</span>
        <button
          className={`${
            upcoming.length < 10 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage < 1 || upcoming.length < 10}
        >
          Next
        </button>
      </div>
      </>


        }

    </div>
  )
})

export default Upcoming_books_return;
