import logo from "./logo.svg";
import { Route, Redirect, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import "./App.css";
import Layout from "./componets/layout/layout";
import Login from "./page/Login/login";
import auth from "./services/auth";
import NotFound from "./page/404";
import axios from "axios";

import { config } from "./config";

function App() {
  const [isAuthenticated, setAuthenticate] = useState(false);

  async function check_token (token) {
    

    let payload = {
      fn: "common_fn",
      se: "to_vi",
      data:{token:token},
    };
  
    let reqbody = JSON.stringify(payload);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

  // console.log(response)
 return response

    }
catch(err){

console.log(err)

}


  }

  useEffect(() => {
    const isAuth = auth.getAuth();

    if (isAuth) {
     check_token(isAuth).then((res)=>{

        if(res.data.status === 0){


          setAuthenticate(true);
        }
        else{
          localStorage.removeItem("token");
        }

     })

    } else {
      setAuthenticate(false);
    }
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        auth.getAuth() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );

  return (
    <div className="App container-fluid">
      <Switch>
        <Route path="/" exact={true}>
          {isAuthenticated ? (
            <Redirect to="/admin" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route path="/login" component={Login} />

        <PrivateRoute path="/admin" component={Layout} />
        <Route component={NotFound} />
 
        {/* <Route path="/admin" component={Layout} /> */}

        {/* <Route path="/admin" component={Layout} /> */}
        {/* 
        <Route path="/*" component={NotFound} /> */}
      </Switch>
    </div>
  );
}

export default App;
