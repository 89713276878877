import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import auth from "../../services/auth";


import axios from "axios";
// import "../Login/Login.css";
import { config } from "../../config";
import "../Login/login.css";

const Login = () => {
  const history = useHistory();
  const usernameRef = useRef("");
  const passwordRef = useRef("");
 
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  //   let payload = {fn:"common_fn",se:"lo_ad", data:{username:username,password:password}}

  // let reqbody = JSON.stringify(payload)

  useEffect(() => {
    if (auth.getAuth()) {
      // <Redirect to="/admin" />;
      history.push("/admin");
    }
  }, [history]);

  const handleUsernameChange = (event) => {
    // setUsername(event.target.value);

    usernameRef.current = event.target.value;
  };

  const handlePasswordChange = (event) => {
    // setPassword(event.target.value);
    passwordRef.current = event.target.value;
  };
  //     /////
  let username;
  let password;

  const handleSubmit = async (e) => {
    e.preventDefault();

    username = usernameRef.current;
    password = passwordRef.current;

    if (username === "" || password === "") {
      setErrorMessage("please enter credential");
    } else {
      let payload = {
        fn: "common_fn",
        se: "lo_ad",
        data: { username: username, password: password },
      };

      let reqbody = JSON.stringify(payload);

      setLoading(true);

      try {
        const response = await axios.post(
          `${config.apiBaseURL}/common`,
          { payload: reqbody },
          { headers: { "Content-Type": "application/json" }}
        );

                //  console.log(response)
        if (response.status === 200) {
          
          if (response.data.status === 0) {


            auth.setAuth(response.data.token);
            history.push("/admin");
          } else {
            setErrorMessage(response.data.msg);
          }
          setLoading(false);
        } else {
          // Handle login error
          const { msg } = await response.data;
          setLoading(false);
          // history.push("/login");
          setErrorMessage(msg);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);

        setErrorMessage("sorry some network issue please try again");
      }
    }
  };
  return (
    <div className="first">
      <div className="form_div">
        <h1 className="heading-login">Welcome</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            autoComplete="off"
          />

          <button type="submit">Login</button>
        </form>
        {loading && <BeatLoader color="#123abc" className="spiner-postion" />}

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Login;
