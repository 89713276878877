function Rackunique_id() {
  const randomString = Math.random().toString(36).substring(2);
  const timestamp = Date.now();
  const prefix = randomString.substring(0, 2); // extract first two characters
  return `${prefix}-${timestamp}`;
}



 function time_difference(issuedate) {

  const pastDate = new Date(issuedate); // Example past date
  const currentDate = new Date(); // Current date
  
  const diffInMs = currentDate.getTime() - pastDate.getTime(); // Difference in milliseconds
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Difference in days, rounded down
  
  // console.log(diffInDays); // Output the difference in days


  return diffInDays




}




module.exports = {
  Rackunique_id,
  time_difference
};
