import React, { useState } from 'react';

import './img_modal.css';
import ImageSlider from './image_slider';



const Img_modal = ({images,setshow_slider}) => {


  const modalStyles = {
    display:'block' 
  };






  const handleClose = () => {
    setshow_slider(false)


  };



if(images ===null || images.length < 0){


    return 
}



  return (
    <div className="submodal" style={modalStyles}>
      <div className="submodal-content">
      <span className="close-button" onClick={handleClose}>
        &#10006;
        </span>

<ImageSlider images={images.img_arr}/>
      
      </div>
    </div>
  );
};

export default Img_modal;
