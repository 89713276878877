import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";
import { config } from "../../config";
import axios from "axios";
import 'sweetalert2/dist/sweetalert2.min.css';

import "../All_book/All_book.css";


//   ///////
const Allbook = (prop) => {
  // console.log(prop);
  const [books, setbooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("no record");
  const [loading, setLoading] = useState(false);
  const [search_query,setsearch_query] = useState("")




  const search_input_handler = (e) =>{

if(e.target.name ==="find_book"){
  setsearch_query(e.target.value)
}

  }



  useEffect(() => {
    async function fetchData() {
      let payload = {
        fn: "common_fn",
        se: "fe_bo",
        data: { limit: 10, page: currentPage },
      };

      let reqbody = JSON.stringify(payload);
      setLoading(true);
      try {
        const response = await axios.post(
          `${config.apiBaseURL}/common`,
          { payload: reqbody },
          { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
        );

        // console.log(response);
        const data = response.data.data;
        if (response.status === 200) {
          if (response.data.status === 0 && data.length > 0) {
            setbooks(data);
          } else if (response.data.status === 1) {
            setbooks([]);
            setErrorMessage('no record')
          }
        }
      } catch (error) {
        console.log(error);
        setErrorMessage("Oops, something went wrong")
      }
      setLoading(false);
    }
    fetchData();
  }, [currentPage]);

  // pass data to manage_book components //
  const Book_edit_handler = (data) => {
    prop.funupdate(data);
  };

//--------------- export excel -----------------------//

   const excelcreate = async() => {
    let payload = {
      fn: "common_fn",
      se: "exl_cr",

    };

    let reqbody = JSON.stringify(payload);

try{

  const response = await axios.post(
    `${config.apiBaseURL}/common`,
    { payload: reqbody },
    { headers: { "Content-Type": "application/json" },     responseType: 'blob',
  }
  );

  console.log(response.status);
  
if(response.status === 200){


  // const url = window.URL.createObjectURL(new Blob([response.data]));
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = 'booklist.xlsx';
  // a.click();




  Swal.fire({
    title: 'Download File',
    text: 'Click the button below to download the file.',

    showCancelButton: true,
    confirmButtonText: 'Download',
    confirmButtonColor: '#c34a6a',
    cancelButtonText: 'Cancel',
    preConfirm: function() {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'booklist.xlsx';
      a.click();
    
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      // URL.revokeObjectURL(url);
    },

    didOpen: () => {
      // Modify the SweetAlert2 dialog background color directly
      const dialog = Swal.getPopup();
      dialog.style.backgroundColor = '#fffff'; // Change this to the desired background color
    },
  });



}

}
catch(err){


console.log(err)

}


   }




   async function search_book(){

//     if(search_query === ''){

// return
//     }

    let payload = {
      fn: "common_fn",
      se: "sr_bo",
      data: { limit: 10, page: currentPage,search:search_query },
    };

    let reqbody = JSON.stringify(payload);
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

      // console.log(response);
      const data = response.data.data;
      if (response.status === 200) {
        if (response.data.status === 0 && data.length > 0) {
          setbooks(data);
          
        } else if (response.data.status === 1) {
          setbooks([]);
          setErrorMessage('no record')
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Oops, something went wrong")
    }

    finally{

setLoading(false)


    }

   }



  















  return (
    <div>
      {loading ? (
        <BeatLoader color="#123abc" className="spiner-postion" />
      ) : (
        <div className="rack-list-container">
          {books && !books.length > 0 ? (
            <p> {errorMessage} </p>
          ) : (
            <table className="rack-table">
              <thead>
                <tr>
                  <th>Book No.</th>
                  <th>Book Name
                
                 <input
                 className="search_input"
                 type="text"
                 id="find_book"
                 name="find_book"
                 placeholder="..type"
                 value={search_query}
                 onChange={search_input_handler}

                 />
                 <button className="search-find" onClick={search_book}>Find</button>
          
               
    
                  </th>
                  <th>Book Author</th>
                  <th>Department</th>
                  <th>Sub Department</th>
                  <th>Rack Name</th>
                  <th>Veer Samvat</th>
                  <th>Size</th>
                  <th>Shape</th>
                  <th>Quality</th>
                  <th>Binding</th>
                  <th>Title Details</th>
                  <th>ISBN Number</th>
                  <th>Publisher</th>
                  <th>status</th>
                  
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {books.map((book) => (
                  <tr key={book.row_id} className="rack-row">
                    <td>{book.book_no}</td>
                    <td className="rack-name" style={{fontSize:"13px"}}>{book.bookname} <br></br> {book.book_hindi_name}</td>
                    <td className="rack-description">{book.authorname}</td>
                    <td className="rack-name">{book.deptname}<br></br> {book.deptname_hindi}</td>
                    <td className="rack-name">{book.subdeptname} <br></br> {book.subdeptname_hindi}</td>
                    <td>{book.rackname}</td>
                    <td className="rack-name">{book.veer_samvat} </td>
                    <td className="rack-name">{book.size} </td>
                    <td className="rack-name">{book.shape} </td>
                    <td className="rack-name">{book.quality} </td>
                    <td className="rack-name">{book.binding} </td>
                    <td className="rack-name">{book.title_detail} </td>
                    <td className="rack-name">{book.isbn} </td>
                    <td className="rack-name">{book.publisher} </td>
                    <td className={book.avail_status ===1 ?"issued":""}>{book.avail_status ===1?"Issued":"available"}</td>
                  
                    <td className="rack-name">
                      <button
                      
                        onClick={() => Book_edit_handler(book)}
                        disabled ={book.avail_status ===1?true:false}
                        className={`btn-edit ${book.avail_status ===1?'btn-edit2':''}`}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/*  pagination  */}
          <div className="sub-page24">
            <div> 
            <button
              className={`${
                currentPage === 1
                  ? "pagination-btn-disabled"
                  : "pagination-btn "
              }`}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="Page-text"> Page {currentPage} </span>
            <button
              className={`${
                books.length < 10
                  ? "pagination-btn-disabled"
                  : "pagination-btn "
              }`}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage < 1 || books.length < 10}
            >
              Next
            </button>
            </div>
           <div> <button className="pagination-btn" style={{backgroundColor:"green",color:"white"}} onClick={excelcreate}>Export as Excel</button> </div>
          </div>
       
        </div>
      )}
    </div>
  );
};

export default Allbook;




  