import { React, useState } from "react";
import MenuBar from "../menubar/menubar";
import login from "../../page/Login/login";
import Issuebook from "../../page/Issuebook/issuebook";
import Managebook from "../../page/manage_book/managa_book";
import Managerack from "../../page/manage_rack/managerack";
import Allbook from "../../page/All_book/All_book";
import Not_return_book from "../../page/return_book/return_book";
import Upcoming_books_return from "../../page/upcoming_book_return/upcoming_book_return";
import Department from "./../../page/manage_dept/Department"
import Subdepartment from "../../page/Sub_department/Sub_dept";
import Book_template from "../../page/book_template/book_template";

import "./layout.css";
import { BrowserRouter as Routes, Switch, Route, Link } from "react-router-dom";
import RackList from "../../page/manage_rack/racklist";

const Layout = () => {
  const [update, setupdate] = useState(Date.now());
  return (
    <div>
      <div className="row m-0 headers">
        <h6 className="heading-title">Library Manangement System</h6>
      </div>
      <div className="row m-0" style={{ height: "100%", overflow: "hidden" }}>
        <div className="col-3  navbar-div" style={{}}>
          <MenuBar setupdate={setupdate} />
        </div>
        <div
          className="col-9 border primary  content-table"
          style={{ padding: "10px" }}
        >
          <Switch key={update}>
            {/* <Route path={"/admin"} exact={true} component={login} /> */}
            <Route
              path={"/admin/issuebook"}
              exact={true}
              component={Issuebook}
            />
            <Route
              path={"/admin/managebook"}
              exact={true}
              component={Managebook}
            />
            <Route
              path={"/admin/managerack"}
              exact={true}
              component={Managerack}
            />
            <Route path={"/admin/Allbook"} exact={true} component={Allbook} />
            <Route
              path={"/admin/not-return-book"}
              exact={true}
              component={Not_return_book}
            />
            <Route
              path={"/admin/upcoming-books-return"}
              exact={true}
              component={Upcoming_books_return}
            />
            <Route path={"/admin/racklist"} exact={true} component={RackList} />
            <Route path={"/admin/department"} exact={true} component={Department}/>
            <Route path={"/admin/subdepartment"} exact={true} component={Subdepartment}/>
            <Route path={"/admin/book-template"} exact={true} component={Book_template}/>
            {/* <Route path={"/admin/status"} exact={true} component={Status} />
              <Route path={"/admin/client/view/:id"} component={ViewClient} />  */}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Layout;
