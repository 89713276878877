import React, { useState, useEffect } from "react";
import Select from "react-select";

import "./../manage_dept/Department.css";

import AllBook from "../All_book/All_book";
import { config } from "../../config";
import { BeatLoader } from "react-spinners";
import remove_icon from "./remove.png"
import axios from "axios";

// function generateRandomTwoDigit() {
//   return Math.floor(Math.random() * 90) + 10;
// }

const Managebook = (props) => {


  const [bookname, setbookName] = useState("");
  const [book_hindi_name, setBook_hindi_name] = useState("")
  const [book_no, setBook_no] = useState("");
  const [author, setAuthor] = useState("");

  const [isbn, setIsbn] = useState("");
  const [Summary, setSummary] = useState("");
  const [book_page, SetBook_page] = useState(0)
  const [image, setImage] = useState(false);
  const [imgurl, SetImgurl] = useState(false);
  const [row_id, setrow_id] = useState(false);
  const [img_arry, setimg_arry] = useState([])
  const [language, setLanguage] = useState("")
  const [Publisher, setPublisher] = useState("")
  const [part, setPart] = useState("")
  const [titleDetails, setTitleDetails] = useState("")
  const [vikramSamvat, setvikramSamvat] = useState("")
  const [isviSamvat, setisviSamvat] = useState("")
  const [veerSamvat, setveerSamvat] = useState("")
  const [avratiName, setavratiName] = useState("")
  const [pages, setPages] = useState("")
  const [price, setPrice] = useState(0)
  const [gmala, setGmala] = useState("")
  const [gmalano, setGmalaNo] = useState("")
  const [size, setSize] = useState("")
  const [shape, setShape] = useState("")
  const [pratiya, setPratiya] = useState("")
  const [position, setPosition] = useState("")
  const [quality, setQuality] = useState("")
  const [binding, setBinding] = useState("")

  //-------------------data state ------------------//
  const [departments, setDepartments] = useState([]);
  const [rack_no, setRack_no] = useState([]);
  const [subdepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectrack_no, setSelect_Rack_no] = useState("");
  const [Select_sub_dept, SetSelect_sub_dpt] = useState("");

  const [copy_dept_id, setCopy_dept_id] = useState('')
  const [copy_subdept_id, setCopy_subdept_id] = useState('')

  // ////// ------- UI state  ----------- ////// //
  const [Msg, setmsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isupload, setIsupload] = useState(false);
  const [show_booklist, setshow_booklist] = useState(false);
  const [valid, setValid] = useState(false)

  // -------------------- state update handler ----------------------  //


  const Input_change_handler = (e) => {


    if (e.target.name === "book-name") {
      setbookName(e.target.value);
    }


    if (e.target.name === "book-name-hindi") {

      setBook_hindi_name(e.target.value)
    }


    if (e.target.name === "author") {
      setAuthor(e.target.value);
    }

    if (e.target.name === "summary") {
      setSummary(e.target.value);
    }

    if (e.target.name === "isbn") {
      setIsbn(e.target.value);
    }

    if (e.target.id === "img2") {


      setIsupload(false)



      setImage(e.target.files[0]);

    }

    if (e.target.name === "book_Page2") {

      SetBook_page(e.target.value);
    }

    if (e.target.name === "language_1") {

      setLanguage(e.target.value);
    }


    if (e.target.name === "Publisher") {

      setPublisher(e.target.value)
    }

    if (e.target.name === "book-no") {

      setBook_no(e.target.value)


    }
    //------------------new fields=================//

    if (e.target.name === "part") {

      setPart(e.target.value)


    }

    if (e.target.name === "titleDetails") {

      setTitleDetails(e.target.value)


    }
    if (e.target.name === "vikramSamvat") {

      setvikramSamvat(e.target.value)


    }
    if (e.target.name === "isviSamvat") {

      setisviSamvat(e.target.value)


    }
    if (e.target.name === "veerSamvat") {

      setveerSamvat(e.target.value)


    }
    if (e.target.name === "avratiName") {

      setavratiName(e.target.value)


    }
    if (e.target.name === "pages") {

      setPages(e.target.value)


    }
    if (e.target.name === "price") {

      setPrice(e.target.value)


    }
    if (e.target.name === "gmala") {

      setGmala(e.target.value)


    }
    if (e.target.name === "gmalano") {

      setGmalaNo(e.target.value)


    }
    if (e.target.name === "size") {

      setSize(e.target.value)


    }
    if (e.target.name === "shape") {

      setShape(e.target.value)


    }
    if (e.target.name === "pratiya") {

      setPratiya(e.target.value)


    }
    if (e.target.name === "position") {

      setPosition(e.target.value)


    }
    if (e.target.name === "quality") {

      setQuality(e.target.value)


    }
    if (e.target.name === "binding") {

      setBinding(e.target.value)


    }




  };
  /**
   * Database Filed - new
   * 
   * 
   * "part"
"title_detail"
"isvi_samvat"
"vikram_samvat"
"avruti_name"
"pages"
"price"
"granthmala"
"granthmala_number"
"size"
"pratiya"
"position"
"binding"
"quality"
"shape"
"veer_samvat"

   */

  // ------------------- ///


  //------------------------- depatment select option-----------------------------------//

  const [searchTerm, setSearchTerm] = useState("");

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);

    setSubDepartments([]);
    SetSelect_sub_dpt("");

    //------ fetch  sub department base on department ---------//

    fetchsubdepart(selectedOption.value);

    find_length(selectedOption.value).then((num) => {


      //-------------- append  sub deparment name  with book number ------------//



      // setBook_no(`${selectedOption.label.substring(0, 4)}-`);




    });
  };



  const handleSearchTermChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  const filteredDepartments = departments.filter((department) =>
    department.deptname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const departmentOptions = filteredDepartments.map((department) => ({
    value: department.row_id,
    // label: department.deptname,
    label: `${department.deptname} ${department.deptname_hindi}`,

  }));

  ///------------------------- sub department select option  -------------------------///

  const [searchTerm_s, setSearchTerm_s] = useState("");

  const handleSubDepartmentChange = (selectedOption) => {
    SetSelect_sub_dpt(selectedOption);

    find_length_sub_dept(selectedOption.value).then((n) => {



      // -------------- append deparment name  with book number ------------------- //

      // if(copy_subdept_id === selectedOption.value){
      //   console.log('true')

      //   setBook_no(`${selectedDepartment.label.substring(0, 4)}-${selectedOption.label.substring(0, 4)}-N0${n}`)


      // }

      // else{
      // setBook_no(`${selectedDepartment.label.substring(0, 4)}-${selectedOption.label.substring(0, 4)}-N0${n + 1}`)

      // }




    })

  };

  const handleSubSearchTermChange = (inputValue) => {
    setSearchTerm_s(inputValue);
  };

  const filteredSubDepartments = subdepartments.filter((department) =>
    department.subdeptname.toLowerCase().includes(searchTerm_s.toLowerCase())
  );

  const SubdepartmentOptions = filteredSubDepartments.map((department) => ({
    value: department.row_id,
    label: `${department.subdeptname}  ${department.subdeptname_hindi}`,
  }));

  const shapeOptions = [
    { "value": "Pustak Roop", "label": "Pustak Roop" }, { "value": "Patrakar Roop", "label": "Patrakar Roop" }
  ]
  const qualityOptions = [
    { "value": "Best", "label": "Best" }, { "value": "Good", "label": "Good" },
    { "value": "Weak", "label": "Weak" }, { "value": "Bad", "label": "Bad" },
  ]
  const positionOptions = [
    { "value": "Complete(Purn)", "label": "Complete(Purn)" }, { "value": "In-Complete(Apurn)", "label": "In-Complete(Apurn)" }
  ]
  const bindingOptions = [
    { "value": "Hard Cover", "label": "Hard Cover" }, { "value": "Cover", "label": "Cover" }, { "value": "Paper Book", "label": "Paper Book" }
  ]

  const sizeOptions = [
    { "value": "A", "label": "A" }, { "value": "B", "label": "B" },
    { "value": "C", "label": "C" }, { "value": "D", "label": "D" },
    { "value": "E", "label": "E" }, { "value": "F", "label": "F" },
    { "value": "G", "label": "G" },
  ]

  //---------------------- rack select option ----------------------//

  const [searchTerm_rack, setSearchTerm_rack] = useState("");

  const handleRackChange = (selectedOption) => {
    console.log(selectedOption);
    setSelect_Rack_no(selectedOption);
  };

  const handleSearchTermChange_r = (inputValue) => {
    setSearchTerm_rack(inputValue);
  };

  const filteredrack = rack_no.filter((rack) =>
    rack.rackname.toLowerCase().includes(searchTerm_rack.toLowerCase())
  );

  const rackOptions = filteredrack.map((rack) => ({
    value: rack.row_id,
    label: `${rack.room}, ${rack.almari},${rack.rackname}`,
  }));

  //---------------------------- fetch  department --------- -------------//

  async function fetchdepart() {
    let payload = {
      fn: "common_fn",
      se: "fe_dpt",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      // console.log(data);
      if (response.status === 200 && response.data.status === 0) {
        setDepartments(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /// --------------------------- fetch sub department -------------- ------------- ///

  async function fetchsubdepart(value) {
    let payload = {
      fn: "common_fn",
      se: "fe_sub_dpt_id",
      data: { dept_id: value },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      // console.log(data);
      if (response.status === 200 && response.data.status === 0) {
        setSubDepartments(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //------------------- find books associate with particular department -----------------//

  const find_length = async (id) => {
    let payload = {
      fn: "common_fn",
      se: "fe_bo_dept",
      data: { dept_id: id },
    };

    let reqbody = JSON.stringify(payload);
    // setLoading(true);
    try {

      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      // console.log(data.length);
      if (response.status === 200 && response.data.status === 0) {
        return data.length;
      }

    } catch (error) {


      console.log(error);


    }
    setLoading(false);
  };


  //------------------- find books associate with particular sub department -----------------//



  const find_length_sub_dept = async (id) => {
    let payload = {
      fn: "common_fn",
      se: "fe_sub_co",
      data: { subdept_id: id },
    };

    let reqbody = JSON.stringify(payload);
    // setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      // console.log(data.length);
      if (response.status === 200 && response.data.status === 0) {
        return data.length;
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };




  // --------------------fetch  rack------------------- //

  async function fetchrack() {
    let payload = {
      fn: "common_fn",
      se: "fe_ra",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

      const data = response.data.data;
      if (response.status === 200) {
        if (response.data.status === 0 && data.length > 0) {
          setRack_no(data);
        } else if (response.data.status === 1) {
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  ///------------ call function when component mount ------------///

  useEffect(() => {

    fetchdepart();
    fetchrack();
  }, []);



  useEffect(() => {



  }, [isupload])
  /**
   * on change select list - shape
   */


  const handleShapeSelection = (selectedOption) => {
    setShape(selectedOption);


  };

  const handleSizeSelection = (selectedOption) => {
    setSize(selectedOption);


  };

  const handlePositionSelection = (selectedOption) => {
    setPosition(selectedOption);


  };
  const handleBindingSelection = (selectedOption) => {
    setBinding(selectedOption);


  };

  const handleQuality = (selectedOption) => {
    setQuality(selectedOption);


  };



  //----------------------------------------//
  // ----- image upload handling  ------///

  const imageupload = async () => {

    try {

      if (!image) {
        return;
      }

      const formData = new FormData();
      formData.append("file", image);

      const response = await axios.post(
        `${config.apiBaseURL}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data", folder: "img" } }
      );
      // console.log(response);
      const data = response.data.data;
      const relative_path = `${data.foPa}${data.name}`
      //  console.log(relative_path)
      // const imgpath = `${config.apiBaseURL}/${data.foPa}${data.name}`;
      // console.log(imgpath)

      setimg_arry((prev) => {
        return [...prev, { url: relative_path }]
      })


      SetImgurl(relative_path);
      setIsupload(true);
    } catch (err) {
      console.log(err);
    }
    finally {
      setImage(false)


    }
  };

  /////////------- new book add ------------ /////////

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      bookname === '' ||
      selectedDepartment.value === '' ||
      Select_sub_dept === "" || selectrack_no==""||
      book_no === ""



    ) {

      setValid(true)
      return;
    }

    // console.log(selectedDepartment, "dept id");
    // console.log(selectrack_no, "rack id");

    const Obj = {
      fn: "common_fn",
      se: "cr_bo",
      data: {
        bookname: bookname,
        book_hindi_name: book_hindi_name,
        authorname: author,
        book_no: book_no,
        Summary: Summary,
        dept_id: selectedDepartment.value,
        rackid: selectrack_no.value,
        isbn: isbn,
        img: imgurl,
        subdept_id: Select_sub_dept.value,
        book_pages: book_page,
        imgs: img_arry,
        language: language,
        Publisher: Publisher,
        part: part,
        titleDetails: titleDetails,
        vikramSamvat: vikramSamvat,
        isviSamvat: isviSamvat,
        veerSamvat: veerSamvat,
        avratiName: avratiName,
        pages: pages,
        price: price,
        gmala: gmala,
        gmalano: gmalano,
        size: size.value||'',
        shape: shape.value||'',
        pratiya: pratiya,
        position: position.value||'',
        quality: quality.value||'',
        binding: binding.value||''
      },
    };

    // console.log(Obj);
    let reqbody = JSON.stringify(Obj);

    // let reqbody = JSON.stringify(payload);

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      // console.log(response);

      if (response.status === 200 && response.data.status === 0) {
        setmsg("success");
        setValid(false);
      } else {
        setmsg("Ops something went wrong");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    setLoading(false);
  };

  ///---------------- book  update  function -------------------///

  const handleUpdate = async (event) => {
    event.preventDefault();
    //  console.log(Select_sub_dept.value,"subdepart")


    const Obj = {
      fn: "common_fn",
      se: "ed_bo",

      data: {
        bookname: bookname,
        book_hindi_name: book_hindi_name,
        authorname: author,
        book_no: book_no,
        Summary: Summary,
        dept_id: selectedDepartment.value,
        rackid: selectrack_no.value,
        isbn: isbn,
        img: imgurl,
        row_id: row_id,
        sub_dept_row_id: Select_sub_dept.value,
        book_pages: book_page,
        imgs: img_arry,
        language: language,
        Publisher: Publisher,
        part: part,
        titleDetails: titleDetails,
        vikramSamvat: vikramSamvat,
        isviSamvat: isviSamvat,
        veerSamvat: veerSamvat,
        avratiName: avratiName,
        pages: pages,
        price: price,
        gmala: gmala,
        gmalano: gmalano,
        size: size.value||'',
        shape: shape.value||'',
        pratiya: pratiya,
        position: position.value||'',
        quality: quality.value||'',
        binding: binding.value||''
      },
    };

    // console.log(Obj,"edit");

    let reqbody = JSON.stringify(Obj);

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );
      // const data = await response.json();
      // console.log(response);
      if (response.status === 200 && response.data.status === 0) {
        setmsg("updated");

        setLoading(false);
      } else {
        setmsg("Ops something went wrong");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  ///------- book edit state update handling------ ///



  const book_edit_handler = (data) => {
    setshow_booklist(false);

    console.log("book -------------------data");
    console.log(data);

    setBook_no(data.book_no);
    setbookName(data.bookname);
    setBook_hindi_name(data.book_hindi_name)
    setAuthor(data.authorname);
    setIsbn(data.isbn);
    setSummary(data.summary);
    setSelect_Rack_no({ label: data.rackname, value: data.rackid });
    setSelectedDepartment({ label: data.deptname, value: data.dept_row_id });
    setrow_id(data.row_id);




    setimg_arry(data.img_arr !== null ? data.img_arr : []);

    setLanguage(data.language !== null ? data.language : "");
    setPublisher(data.publisher !== null ? data.publisher : "")

    setPart(data.part !== null ? data.part : "")
    setTitleDetails(data.title_detail !== null ? data.title_detail : "")
    setvikramSamvat(data.vikram_samvat !== null ? data.vikram_samvat : "")
    setisviSamvat(data.isvi_samvat !== null ? data.isvi_samvat : "")
    setveerSamvat(data.veer_samvat !== null ? data.veer_samvat : "")
    setavratiName(data.avruti_name !== null ? data.avruti_name : "")
    setPages(data.pages !== null ? data.pages : "")
    setPrice(data.price !== null ? data.price : "")
    setGmala(data.granthmala !== null ? data.granthmala : "")
    setGmalaNo(data.granthmala_number !== null ? data.granthmala_number : "")
    setSize(data.size !== null ? { "label": data.size, "value": data.size } : "")
    setShape(data.shape !== null ? { "label": data.shape, "value": data.shape } : "")
    setPratiya(data.pratiya !== null ? data.pratiya : "")
    setPosition(data.publisher !== null ? { "label": data.position, "value": data.position } : "")
    setQuality(data.publisher !== null ? { "label": data.quality, "value": data.quality } : "")
    setBinding(data.binding !== null ? { "label": data.binding, "value": data.binding } : "")

    SetBook_page(data.book_pages);
    SetSelect_sub_dpt({ label: data.subdeptname, value: data.sub_dept_row_id });

    setCopy_dept_id(data.dept_row_id)
    setCopy_subdept_id(data.sub_dept_row_id)

    fetchsubdepart(data.dept_row_id);
  };


  // console.log(selectedDepartment, "depart");
  // console.log(selectrack_no, "rack");

  //------------------- delete uploaded image handler function p-------------------------
  useEffect(() => {


  }, [img_arry])

  function delete_upload_img(indexToRemove) {

    console.log(indexToRemove)

    const newArr = img_arry.filter((_, index) => index !== indexToRemove);
    setimg_arry(newArr)


  }





  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      width: "205px",
      height: "30px",
      minHeight: "35px",
      fontSize: "13px",


      // marginBottom :"20px"

      // Set the height of the input
    }),
    menu: (provided) => ({
      ...provided,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "scroll", // Add the 'y' axis scrolling
      fontSize: "13px",
    }),
  };



  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      width: "205px",
      height: "30px",
      minHeight: "35px",

      border: "2px solid red"

      // marginBottom :"20px"

      // Set the height of the input
    }),
    menu: (provided) => ({
      ...provided,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "scroll", // Add the 'y' axis scrolling
    }),
  };


  return (
    <>
      {loading ? (
        <BeatLoader />
      ) : (
        <>
          {!show_booklist ? (
            <div>
              {Msg ? (
                <p style={{ color: "green", fontWeight: "bold" }}>{Msg}</p>
              ) : (
                <>
                  <div className="MainDivForInputField">
                    <p style={{ width: "100%", textAlign: "left", fontWeight: "bold" }}>{row_id ? "Update book" : 'Add New Book'} </p>


                    <div className="InputRow-left">
                      <div className="select_input">
                        <label htmlFor="department_select" className="input-label-b">
                          Department
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="department_select"
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                            options={departmentOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSearchTermChange}
                            styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="InputRow-left">
                      <div className="select_input">
                        <label htmlFor="subdepartment_select" className="input-label-b">
                          Sub Department
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="subdepartment_select"
                            value={Select_sub_dept !== null ? Select_sub_dept : ''}
                            onChange={handleSubDepartmentChange}
                            options={SubdepartmentOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSubSearchTermChange}

                            styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="book-no" className="input-label-b">
                          Book No
                        </label>
                        <div className="input-container-b">
                          <input
                          className={valid ? 'input-validate' : ""}
                            type="text"
                            id="book-no"
                            name="book-no"
                            value={book_no}
                            // readOnly
                            onChange={Input_change_handler}
                            placeholder="Enter unique no"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="book-nameeng" className="input-label-b">
                          Book Name (Eng)
                        </label>
                        <div className="input-container-b">
                          <input
                            className={valid ? 'input-validate' : ""}
                            type="text"
                            id="book-nameeng"
                            name="book-name"
                            value={bookname}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="book-namehindi" className="input-label-b">
                          Book Name (हिन्दी)
                        </label>
                        <div className="input-container-b">
                          <input
                            // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="book-namehindi"
                            name="book-name-hindi"
                            value={book_hindi_name !== null ? book_hindi_name : ''}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="author" className="input-label-b">
                          Author Name
                        </label>
                        <div className="input-container-b">
                          <input
                            // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="author"
                            name="author"
                            value={author}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Publisher
                        </label>
                        <div className="input-container-b">
                          <input
                            // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="Publisher"
                            name="Publisher"
                            value={Publisher}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Part
                        </label>
                        <div className="input-container-b">
                          <input
                            // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="part"
                            name="part"
                            value={part}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Title Details
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="titleDetails"
                            name="titleDetails"
                            value={titleDetails}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Vikram Samvat
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="vikramSamvat"
                            name="vikramSamvat"
                            value={vikramSamvat}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Isvi Samvat
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="isviSamvat"
                            name="isviSamvat"
                            value={isviSamvat}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Veer Samvat
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="veerSamvat"
                            name="veerSamvat"
                            value={veerSamvat}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Avruti Name
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="avratiName"
                            name="avratiName"
                            value={avratiName}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          No of Pages
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="pages"
                            name="pages"
                            value={pages}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Price
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="price"
                            name="price"
                            value={price}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Granth Mala
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="gmala"
                            name="gmala"
                            value={gmala}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Granth Mala Number
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="gmalano"
                            name="gmalano"
                            value={gmalano}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Size
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="size"
                            value={size !== null ? size : ''}
                            onChange={handleSizeSelection}
                            options={sizeOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSubSearchTermChange}

                            styles={customStyles}
                            // styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Shape
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="shape"
                            value={shape !== null ? shape : ''}
                            onChange={handleShapeSelection}
                            options={shapeOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSubSearchTermChange}

                            styles={customStyles}
                            // styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Pratiya
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="pratiya"
                            name="pratiya"
                            value={pratiya}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Position
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="position"
                            value={position !== null ? position : ''}
                            onChange={handlePositionSelection}
                            options={positionOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSubSearchTermChange}

                            styles={customStyles}
                            // styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Quality
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="quality"
                            value={quality !== null ? quality : ''}
                            onChange={handleQuality}
                            options={qualityOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSubSearchTermChange}

                            styles={customStyles}
                            // styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Binding
                        </label>
                        <div style={{ marginTop: "6px" }}>
                          <Select
                            id="binding"
                            value={binding !== null ? binding : ''}
                            onChange={handleBindingSelection}
                            options={bindingOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSubSearchTermChange}

                            styles={customStyles}
                            // styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="isbn" className="input-label-b">
                          Isbn No
                        </label>
                        <div className="input-container-b">
                          <input
                            type="text"
                            id="isbn"
                            name="isbn"
                            value={isbn !== null ? isbn : ''}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="summary" className="input-label-b">
                          Summary
                        </label>
                        <div className="input-container-b">
                          <input
                            type="text"
                            id="summary"
                            name="summary"
                            value={Summary}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div className="select_input">
                        <label htmlFor="rack_select" className="input-label-b">
                          Rack
                        </label>
                        <div>
                          <Select
                            id="rack_select"
                            value={selectrack_no}
                            onChange={handleRackChange}
                            options={rackOptions}
                            isSearchable
                            placeholder="Search or select"
                            onInputChange={handleSearchTermChange_r}
                            styles={valid ? customStyles2 : customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="book_Page2" className="input-label-b">
                          Pages of book
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="book_Page2"
                            name="book_Page2"
                            value={book_page}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="InputRow-left">
                      <div>
                        <label htmlFor="language_1" className="input-label-b">
                          Language
                        </label>
                        <div className="input-container-b">
                          <input
                          // className={valid ? 'input-validate' : ""}
                            type="text"
                            id="language_1"
                            name="language_1"
                            value={language}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="InputRow-right">
                      <div>
                        <label htmlFor="img2" className="input-label-b">
                          Image
                        </label>
                        <div className="choosfile">
                          <input
                            className={isupload ? "upload_file" : ""}

                            type="file"
                            id="img2"
                            name="img2"
                            onChange={Input_change_handler}
                          />

                          <button className="upload-btn" onClick={imageupload}>
                            {isupload ? "Uploaded" : "Upload"}

                          </button>



                        </div>

                      </div>
                    </div>




                    <div className="InputRow-right">

                      <div className="input-container-jj">
                        <button
                          className="dept-btn"
                          onClick={row_id ? handleUpdate : handleSubmit}
                        >
                          {row_id ? "Update" : "Submit"}
                        </button>
                      </div>
                      {!row_id && (
                        <div className="input-container-jj">
                          <button
                            className="dept-btn"
                            onClick={() => {
                              setshow_booklist(true);
                            }}
                          >
                            View
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {img_arry.length > 0 &&
                    <div className="side_img_list"><ul>
                      {
                        img_arry.map((e, i) => {

                          return <li style={{ fontSize: "12px" }}>Img--{i + 1}  <img style={{ width: '13px', height: "13px", marginInline: "8px" }} src={`${remove_icon}`} onClick={() => {

                            delete_upload_img(i)

                          }} />
                          </li>

                        })
                      }


                    </ul>
                    </div>
                  }


                </>
              )}
            </div>
          ) : (
            <AllBook funupdate={book_edit_handler} />
          )}
        </>
      )}
    </>
  );
};

export default Managebook;

