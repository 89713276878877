import React, { useState } from "react";
import "./Not_return_book.css";
import Book_list from "./book_return_list";
import { BeatLoader } from "react-spinners";
import { config } from "../../config";
import axios from "axios";

// let handleSearchSubmit;

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
}



function Return_book() {
  
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  
  
  ///-----------data state ---------------///
  
  const [Mobile, setMobile] = useState("");
  const [book_no,setBook_no] = useState("")



 ////-------------UI state --------------////
  const [msg2,setMsg2] = useState(false)
  const [msg,setMsg]   = useState("")
  const [isvalid,setIsValid] = useState(false)
  const [showmsg, setShowmsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returntype, setReturntype] = useState('bookno');


  // const [update, setupdate] = useState(Date.now());


  const [books, setBooks] = useState([]);




    const input_change_handler  = (e) =>{

     if(e.target.name ==="returntype"){

       if(e.target.value ==="bookno"){
        setBooks([])
        setMobile("")
       }
           setReturntype(e.target.value)

     }
     if(e.target.name ==="bookno"){

      setBook_no(e.target.value)
     }
       if(e.target.name ==="mobile_no"){

        setMobile(e.target.value)
       }

    }





     ////--------find book with mobile number  for return ---------------- /////

  const handleSearchSubmit = async () => {

    setSearchPerformed(false);
    setSearchResults([]);
 
          if(Mobile ==="") {

            return 
          }


    
      let data = {
        mobile: Mobile,
      };
      let payload = { fn: "common_fn", se: "se_is_bo", data: data };

      let reqbody = JSON.stringify(payload);

      setLoading(true);


      try {
        const response = await axios.post(
          `${config.apiBaseURL}/common`,
          { payload: reqbody },
          { headers: { "Content-Type": "application/json" }}
        );
        console.log(response);
        // console.log(response.data.data);
        const data = response.data.data;
        if (response.status === 200 && response.data.status === 0) {
          // console.log(data);

          setSearchPerformed(false);
          setBooks(data);
        } if(response.status === 200 && response.data.status === 1){
                  setMsg("No Record Found")
             setSearchPerformed(true)
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setSearchPerformed(true)
      }
     
    // setMobile("");
    setLoading(false);
  };

  /////// book return function   //////

  const handleReturnBook = async (book) => {
    console.log(book);
    setLoading(true);
    let data = {
      bookissueid: book.bookissueid,
    //  bookno:book.bookno
    };

    let payload = { fn: "common_fn", se: "re_bo", data: data };

    let reqbody = JSON.stringify(payload);

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
      console.log(response);
      // console.log(response.data.data);
      const data = response.data.data;
      if (response.status === 200 && response.data.status === 0) {
        console.log(data);
        // setupdate(Date.now());
        setMsg("Successfully Return")
        setShowmsg(true);

        setLoading(false);
      } else if (response.data.status === 1) {
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  


//////----------direct book return with book no  -------------------////


const handleReturnBookwithNo = async () => {

   if(book_no ===""){
 setIsValid(true)
    return
      }
    

  let data = {

   bookno:book_no
  };

  let payload = { fn: "common_fn", se: "re_bo_no", data: data };

  let reqbody = JSON.stringify(payload);


setLoading(true)

  try {
    const response = await axios.post(
      `${config.apiBaseURL}/common`,
      { payload: reqbody },
      { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
    );
    console.log(response);
    
    const data = response.data.data;
    if (response.status === 200 && response.data.status === 0) {
      console.log(data);
      setMsg("Successfully Return")
      setShowmsg(true);
      setLoading(false);
    } 

    if (response.status === 200 && response.data.status === 2) {
      console.log(data);
      
    setMsg2("Incorrect book no")
    
      setLoading(false);
    } 

    setLoading(false);
  } catch (error) {
    console.error(error);
  }
  setLoading(false);
};
























  return (
    <div>
      {loading ? (
        <BeatLoader color="#123abc" className="spiner-postion" />
      ) : (
        <div>
          {!showmsg ? (
            <div className="input-div">
              <div className="select_div">
              <label htmlFor="selectOption">Return with:</label>
      <select id="selectOption" name= "returntype" value={returntype} onChange={input_change_handler}>
        
        <option value="bookno">Book No</option>
        <option value="mobileno">Mobile No</option>
      </select>
</div>
          { returntype ==="bookno"? <div className="input-rows">
                <div>
                  <label htmlFor="bookno" className="input-labels">
                  Book No
                  </label>
                  <div className="input-containers">
                    <input
                    className="valid_input"
                      type="text"
                      id="bookno"
                      name='bookno'
                      value={book_no}
                      onChange={input_change_handler}
                    />
                  </div>
                  { msg2 && <p style={{color:"red"}}>{msg2}</p>}
                </div>
                <div>
                  <div className="input-containers">
                    <button className="src_btn" onClick={handleReturnBookwithNo}>
                      Return
                    </button>
                  </div>
                </div>
              </div>:""


          }   
          {
                returntype ==="mobileno"?
                <div className="input-rows">
                <div>
                  <label htmlFor="mobile" className="input-labels">
                    Mobile No
                  </label>
                  <div className="input-containers">
                    <input
                      type="text"
                      id="mobile"
                      value={Mobile}
                      name="mobile_no"
                      onChange={input_change_handler}
                    />
                  </div>
                </div>
                <div>
                  <div className="input-containers">
                    <button className="src_btn" onClick={handleSearchSubmit}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
               :""


          }
          
          
          
          

              {
                // Render the search results only if the search has been performed
                books.length > 0 ? (
                  //        ///////        book list

                  <div>
                    <div className="book-list-container">
                      {loading ? (
                        <BeatLoader
                          color="#123abc"
                          className="spiner-postion"
                        />
                      ) : (
                        <table className="book-list">
                          <thead>
                            <tr>
                              <th>Book Name</th>
                              <th>Author</th>
                              <th>Issue Date</th>
                              <th>User</th>
                              <th>Mobile</th>
                              <th>Return</th>
                            </tr>
                          </thead>
                          <tbody style={{fontSize:"13px"}}>
                            {books.map((book, index) => (
                              <tr key={index}>
                                <td>{book.bookname}<br></br> {book.book_hindi_name}</td>
                                <td>{book.authorname}</td>
                                <td>{formatDate(book.cr_on)}</td>
                                <td>{book.fullname}</td>
                                <td>{book.mobile}</td>
                                <td>
                                  <button
                                    onClick={() =>
                                      handleReturnBook(book, index)
                                    }
                                  >
                                    Return
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                ) : (
                 ////// ---book list end ----/////
                  searchPerformed && <p> {msg}</p>
                )
              }
            </div>
          ) : (
            <p style={{ color: "green", fontFamily: "sans-serif" }}>
            {msg}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default Return_book;

