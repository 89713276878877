import React, { useState } from "react";

import "./issuebook.css";
import NewUserform from "../../componets/form/NewUserform";
import Card from "../../componets/card/card";
import Book from "./Books";
import { BeatLoader } from "react-spinners";
import { config } from "../../config";
import axios from "axios";

function Issuebook() {
  // const [searchTerm, setSearchTerm] = useState("");

  const [Mobile, setMobile] = useState("");
  //
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [formshow, setFormshow] = useState(false);
  const [successmsg, setsuccessmsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("successful issue book");

  const [isvalid, setisvalid] = useState(true);
  // const [update, setupdate] = useState(Date.now());

  // /// ---- mobile input handler /////

  const input_change_mob = (e) => {
    if (e.target.value.trim().length > 0) {
      setisvalid(true);
    }
    setMobile(e.target.value);
  };

  // --------------------- find user --------------------------------//
  const handleSearchSubmit = async () => {
    setSearchPerformed(false);
    setSearchResults([]);
    if (Mobile.trim().length === 0) {
      setisvalid(false);
      return;
    }

    setLoading(true);
    let data = {
      mobile: Mobile,
    };
    let payload = { fn: "common_fn", se: "se_us", data: data };

    let reqbody = JSON.stringify(payload);

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

      const data = response.data.data;
      if (response.status === 200 && response.data.status === 0) {
        setSearchResults([data]);
      }
      setSearchPerformed(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }

    setMobile("");
    setLoading(false);
  };

  // ///////////user edit data pass to child --> form component///
  const [userobj, setuserObj] = useState("");

  const editClickhander = (userdata) => {
    setuserObj(userdata);

    setSearchPerformed(false);
    setFormshow(true);
  };

  // success msg display //
  const successmsghandler = (msg) => {
    setMsg(msg);
    setsuccessmsg(true);
  };

  return (
    <div>
      {loading ? (
        <BeatLoader color="#123abc" className="spiner-postion" />
      ) : (
        <div>
          {!successmsg ? (
            <div className="input-div">
              {!formshow && (
                <div className="input-rows">
                  <div>
                    <label htmlFor="full-name" className="input-labels">
                      Mobile No
                    </label>
                    <div
                      className={`input-containers ${
                        !isvalid ? "invalid-1" : ""
                      }`}
                    >
                      <input
                        type="text"
                        id="full-name"
                        value={Mobile}
                        onChange={input_change_mob}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="input-containers">
                      <button onClick={handleSearchSubmit} className="src_btn">
                        Search
                      </button>
                      <span
                        className="create-new-user"
                        href="#"
                        onClick={() => {
                          setFormshow(true);
                          setSearchPerformed(false);
                        }}
                      >
                        Create User
                      </span>
                    </div>

                    {/* <div className="input-containers"> */}

                    {/* </div> */}
                  </div>
                </div>
              )}

              {searchPerformed &&
                // Render the search results only if the search has been performed
                (searchResults.length > 0 ? (
                  <Card data={searchResults} Edit_fun={editClickhander} />
                ) : (
                  <p>Not found.</p>
                ))}

              {searchResults.length > 0 && searchPerformed && (
                <Book fun={successmsghandler} user={searchResults} setloading ={setLoading} />
              )}
            </div>
          ) : (
            // sucesss or fail msg display  //
            <p style={{ color: "green", fontFamily: "sans-serif" }}>{msg}</p>
          )}
          {/* {!searchPerformed && null}{" "} */}
          {/* Render the "Enter a mobile number to search" message only if the search has not been performed */}
          {formshow && <NewUserform editdata={userobj} />}{" "}
          {/* Render the NewUserform only if the search has been performed */}
        </div>
      )}
    </div>
  );
}

export default Issuebook;
