import { Link } from "react-router-dom";
import "./404.css";

function NotFound() {
  return (
    <div>
      <h1 className="h-404">404 - Page not found</h1>
      <p className="p-404">We're sorry, the page you requested could not be found.</p>
      <Link to="/" className="link-404">Go back to the Dashboard</Link>
    </div>
  );
}

export default NotFound;
