import React, { useState } from "react";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./book.css";
import { config } from "../../config";
import axios from "axios";

const Book = (prop) => {
  const successmsghandler = prop.fun;
  const userinfo = prop.user[0];
  const SetLoading = prop.setloading

  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [msg, setmsg] = useState(false);
  const [notfound, setnotfound] = useState(false);

  /// ----------------fetch books----------------- ///
  const fetchProducts = async () => {
    setnotfound(false);
    setProducts([]);

 
    if (query.length === 0) {
      return;
    }

    let data = {
      searchbook: query.trim(),
    };
    let payload = { fn: "common_fn", se: "se_bo", data: data };

    let reqbody = JSON.stringify(payload);

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
      console.log(response.data.data);
      const data = response.data.data;
      if (data && data.length > 0) {
        setProducts(data);
      } else {
        setnotfound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // query value input
  const handleInputChange = (event) => {
    if (event.target.value.length === 0) {
      setnotfound(false);
      setProducts([]);
    }
    setQuery(event.target.value);
  };

  // ----book add to cart handling----  //
  const handleAddToCart = (product) => {
    setProducts([]);
    setQuery("");
    console.log(product)
    const newItem = {
      name: product.bookname,
      author: product.authorname,
      bookid: product.row_id,
      userid: userinfo.row_id,
      bookno:product.book_no,
      hindi_name:product.book_hindi_name
    };

    setCartItems([...cartItems, newItem]);
  };

  ////// ----- cart book delete ------//////
  const deletehandle = (index) => {
    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItems(newCartItems);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  //////////// -----otp sending  handle -------     //////////////
  const handleSendOtp = async () => {
   

    let data = {
      mobile: userinfo.mobile,
    };
    let payload = { fn: "common_fn", se: "se_ot", data: data };

    let reqbody = JSON.stringify(payload);
    setShowOtp(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
      console.log(response);
      const data = response.data.data;
      if (response.status === 200) {
        // console.log(data);

        setShowOtp(true);
      }
    } catch (error) {
      console.error(error);
      successmsghandler("Opps,something went wrong");
    }



  };

  ////// verify otp handling and display success msg ///////
  const handleVerifyOtp = async () => {
    

   if(!otp){

      return

   }

    console.log(`Verifying OTP: ${otp}`);
    setmsg(false);
    // await final_book_issue(cartItems);
    let data = {
      mobile: userinfo.mobile,
      otp: otp,
    };
    let payload = { fn: "common_fn", se: "ve_ot", data: data };

    let reqbody = JSON.stringify(payload);

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
      console.log(response);
      console.log(response.status);
      const data = response.data;


      if (response.status === 200 && data.status === 0) {
        console.log("verify");
        setShowOtp(false);
        SetLoading(true)
        const result = await final_book_issue(cartItems);

        if (result === "success") {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Book has been issued successfully.',
            timer: 10000, // The alert will automatically close after 2 seconds
            confirmButtonColor: '#c34a6a'
          });
          successmsghandler("successful issue book");
          SetLoading(false)
        } else if (result === "fail") {
          successmsghandler("failed try again");
        }
      } 
      
     if (response.status === 200 && data.status === 1) {
      console.log("in --verify ");
        setmsg(true);
      }


    } catch (error) {
      console.error(error);
      successmsghandler("Opps,something went wrong");
    }

    // successmsghandler();


  };

  //////////////-------   final issue request --------////////////

 async function final_book_issue  (issuedata) {
    let status = "fail";
    console.log(issuedata);
    for (let i = 0; i < issuedata.length; i++) {
      let book_id = issuedata[i].bookid;
      let user_id = issuedata[i].userid;
      let bookno  = issuedata[i].bookno
      let data = {
        bookid: book_id,
        userid: user_id,
        returnafter: 30,
        bookno:bookno
      };

      // console.log(data);

      let payload = { fn: "common_fn", se: "is_bo", data: data };

      let reqbody = JSON.stringify(payload);

      try {
        const response = await axios.post(
          `${config.apiBaseURL}/common`,
          { payload: reqbody },
          { headers: { "Content-Type": "application/json" }}
        );
        // console.log(response);
        const data = response.data;

        if (response.status === 200 && data.status === 0) {
          // console.log("book _issued");
          status = "success";
      
          // successmsghandler();
        }
      } catch (error) {
        console.error(error);
      }
    }
    //  loop end //
    return status;
  };

  // ////////////////////////////

  return (
    <div className="product-search-container">
      <div className="search-container">
        <h6>Book Request</h6>
        <p>search book (name,barcode,book no)</p>
        <input
          type="text"
          placeholder="Search products"
          valuqe={query}
          onChange={handleInputChange}
        />
        <button className="src_btns" onClick={() => fetchProducts()}>
          Search
        </button>
        <div className="search-dropdown">
          {products.map((product) => (
            <div
              key={product.id}
              className={`search-dropdown-item ${product.avail_status === 1 ? 'issued' : ''}`}
              onClick={() => product.avail_status ===0 ? handleAddToCart(product) : null}
              
            >
              {product.bookname}- {product.book_hindi_name} --{product.book_no}
            </div>
          ))}
          {notfound && <p>no record</p>}
        </div>
      </div>
      <div className="cart-container">
        <h6 style={{ fontWeight: "bold" }}>Book Add</h6>
        {cartItems.length > 0 ? (
          <>
            <ul className="book-containers">
              {cartItems.map((item, i) => (
                <li key={i}>
                 {i+1}. {item.name} --- {item.hindi_name} {" "}
                  <span
                    onClick={() => {
                      deletehandle(i);
                    }}
                    className="close-btn"
                    >
                   🗙
                  </span>
                  <br/>
                  <span style={{color:"green"}}>{item.bookno}</span> 
                </li>
              ))}
            </ul>
            {showOtp ? (
              <div className="otp-verification">
                <label htmlFor="otp-input">Enter OTP:</label>
                <input
                  type="number"
                  id="otp-input"
                  value={otp}
                  onChange={handleOtpChange}
                />
                <button className="src_btns" onClick={handleVerifyOtp}>Verify OTP</button>
              </div>
            ) : (
              <button className="src_btns" onClick={handleSendOtp}>Send OTP</button>
            )}

            {msg && <p style={{ color: "red" }}> Invalid OTP </p>}
          </>
        ) : (
          <p>No book added</p>
        )}
      </div>
    </div>
  );
};

export default Book;
