export const config = {
  // apiBaseURL:"http://192.168.29.27:4000",

  // apiBaseURL: "http://localhost:4000",



  apiBaseURL: window.location.origin


};


