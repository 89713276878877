import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "./../../config";
import { BeatLoader } from "react-spinners";

import "./../manage_rack/managerack.css"

//   ///////
const SubDepartment_list = (prop) => {
  const dpt_edithandler = prop.fun;


  const [dept, setDept] = useState([]);

  

  const [errorMessage, setErrorMessage] = useState("No Record");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  ///------ fetch data ---------///
  useEffect(() => {
    async function fetchsubdepart() {
      setLoading(true);
      let payload = {
        fn: "common_fn",
        se: "fe_sub_dpt",
        data: { limit: 10, page: currentPage },
      };

      let reqbody = JSON.stringify(payload);
      // Fetch racks data from
      try {
        const response = await axios.post(
          `${config.apiBaseURL}/common`,
          { payload: reqbody },
          { headers: { "Content-Type": "application/json" }}
        );

     
        const data = response.data.data;
        if(response.status === 200 && response.data.status ===0){

            setDept(data)
        }

   
      
   
       
      } catch (error) {
        console.log(error);
     
      }

      setLoading(false);
    }
    fetchsubdepart();
  }, [currentPage]);

  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //edit button handdler ///


  const editbtnhandler = (dept) => {
    dpt_edithandler(dept);
  };



  return (
    <div className="rack-list-container">
      {loading ? (
        <BeatLoader color="#123abc" className="spiner-postion" />
      ) : (
        <div>
          {dept && !dept.length > 0 ? (
            <p>{errorMessage}</p>
          ) : (
            <table className="rack-table">
              <thead>
                <tr>
                  <th>Dept Id</th>
                  <th>Sub Dept Name</th>
                  <th>Dept Name</th>
  
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {dept.map((e,i) => (
                  <tr key={i} className="rack-row">
                    <td>{e.subdept_id}</td>
                    <td className="rack-name">{e.subdeptname}<br></br> {e.subdeptname_hindi}</td>
                    <td className="rack-name">{e.deptname} <br></br> {e.deptname_hindi} </td>
                    <td className="rack-name">
                      <button
                        onClick={() => editbtnhandler(e)}
                        className="btn-edit-r"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      <div className="sub-page-div">
        <button
          className={`${
            currentPage === 1 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="Page-text">Page {currentPage}</span>
        <button
          className={`${
            dept.length < 10 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage < 1 || dept.length < 10}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SubDepartment_list;
