import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./menubar.css";
import issuebook from "../../assert/icons/issue_book.png";
import managebook from "../../assert/icons/manage_book.png";
import managerack from "../../assert/icons/book-shelf.png";
import upcoming from "../../assert/icons/return.png";
import notreturn from "../../assert/icons/book-keeping.png";
import logout from "../../assert/icons/logout.png";

function MenuBar(prop) {
 
  const history = useHistory();
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (link) => {
    setActiveLink(link);
  };

  const Logouthandler = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return (
    <div className="left-side-menu">
      <div className="logo">{/* Your logo here */}</div>
      <ul className="menu-items">
        <li
          onClick={() => {
            document.querySelector("#link-1").click();
            prop.setupdate(Date.now());
          }}
          className={`${activeLink === "Issue Book" ? "active" : ""}`}
        >
          <Link
            to="/admin/issuebook"
            id="link-1"
            onClick={() => handleClick("Issue Book")}
          >
            <img src={issuebook} alt="isssue" />
            <span>Issue Book</span>
          </Link>
        </li>
        <li
          onClick={() => document.querySelector("#link-2").click()}
          className={`${activeLink === "managebook" ? "active" : ""}`}
        >
          <Link
            to="/admin/managebook"
            id="link-2"
            onClick={() => {
              handleClick("managebook");
              prop.setupdate(Date.now());
            }}
          >
            <img src={managebook} alt="book" />
            <span>Manage Books</span>
          </Link>
        </li>



        <li
          onClick={() => document.querySelector("#link-6").click()}
          className={`${activeLink === "managedept" ? "active" : ""}`}
        >
          <Link
            to="/admin/department"
            id="link-6"
            onClick={() => {
              handleClick("managedept");
              prop.setupdate(Date.now());
            }}
          >
            <img src={managebook} alt="book" />
            <span>Manage Department</span>
          </Link>
        </li>

        <li
          onClick={() => document.querySelector("#link-9").click()}
          className={`${activeLink === "managesubdept" ? "active" : ""}`}
        >
          <Link
            to="/admin/subdepartment"
            id="link-9"
            onClick={() => {
              handleClick("managesubdept");
              prop.setupdate(Date.now());
            }}
          >
            <img src={managebook} alt="book" />
            <span>Manage Sub department</span>
          </Link>
        </li>


        <li
          onClick={() => document.querySelector("#link-3").click()}
          className={`${activeLink === "managerack" ? "active" : ""}`}
        >
          <Link
            to="/admin/managerack"
            id="link-3"
            onClick={() => {
              handleClick("managerack");
              prop.setupdate(Date.now());
            }}
          >
            <img src={managerack} alt="rack" />
            <span>Manage Racks</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/admin/Allbook">
       
            <span>All Books</span>
          </Link>
        </li> */}
        <li
          onClick={() => document.querySelector("#link-4").click()}
          className={`${activeLink === "noreturn" ? "active" : ""}`}
        >
          <Link
            to="/admin/not-return-book"
            id="link-4"
            onClick={() => {
              handleClick("noreturn");
              prop.setupdate(Date.now());
            }}
          >
            <img src={notreturn} alt="Contact" />
            <span>Return Book</span>
          </Link>
        </li>


        <li
          onClick={() => document.querySelector("#link-5").click()}
          className={`${
            activeLink === "Upcoming Books Return"
              ? "active"
              : "menu-itemslis:hover"
          }`}
        >
          <Link
            to="/admin/upcoming-books-return"
            id="link-5"
            onClick={() => handleClick("Upcoming Books Return")}
          >
            <img src={upcoming} alt="upcoming" />
            <span>Upcoming Books Return</span>
          </Link>
        </li>

        <li
          onClick={() => document.querySelector("#link-11").click()}
          className={`${
            activeLink === "Share book"
              ? "active"
              : "menu-itemslis:hover"
          }`}
        >
          <Link
            to="/admin/book-template"
            id="link-11"
            onClick={() => handleClick("Share book")}
          >
            <img src={upcoming} alt="upcoming" />
            <span>Share book</span>
          </Link>
        </li>


        <li onClick={() => document.querySelector("#link-6").click()}>
          <Link to ="#" onClick={Logouthandler} id="link-6">
            <img src={logout} alt="logout" />
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuBar;
